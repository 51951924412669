@font-face {
  font-family: 'AtypText';
  src: url('../assets/fonts/AtypDisplay-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.slider-container {
  position: relative;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.slider-background {
  background-color: #0093E9;
  background-image: linear-gradient(180deg, #0093E9 0%, #001fc1 100%);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 100%;
  z-index: -1;
  border-radius: 50px;
}

.slider-content {
  font-family: 'AtypText', sans-serif;
  font-size: 140px; /* Original font size for desktop */
  line-height: 0.9;
  padding: 100px 40px; /* Adjust padding as needed */
  margin-bottom: 20px;
  max-width: 1200px;
  width: 100%;
}

.chat-button {
  position: absolute;
  bottom: 30px;
  right: 120px;
  transform: translateY(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: #FFD700;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.chat-button:hover {
  background-color: #FEB80A;
  transform: translateY(45%) scale(1.1);
}

/* Tablet View */
@media (max-width: 1024px) {
  .slider-content {
    font-size: 120px; 
    padding: 80px;
  }

  .chat-button {
    bottom: 40px;
    right: 80px;
    width: 110px;
    height: 110px;
  }
}

/* Medium Devices */
@media (max-width: 768px) {
  .slider-background {
    width: 100%;
    height: 90%;
    border-radius: 30px;
  }

  .slider-content {
    font-size: 100px; 
    font-weight: bolder;
    padding: 40px;
    max-width: 500px;
  }

  .chat-button {
    bottom: 50px;
    right: 50px;
    width: 90px;
    height: 90px;
  }
}

/* Small Devices */
@media (max-width: 480px) {
  .slider-background {
    width: 100%;
    height: 700px;
    border-radius: 0px;
  }

  .slider-content {
    font-size: 80px !important;
    font-weight: 800; 
    padding: 10px 5px;
 
  }
  
  .chat-button {
    bottom: 50px;
    right: 10px;
    width: 140px;
    height: 100px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
  }
}

/* Extra Small Devices */
@media (max-width: 360px) {
  .slider-content {
    font-size: 60px;
    padding: 10px;
  }

  .chat-button {
    bottom: 100px;
    right: 5px;
    width: 80px;
    height: 80px;
  }
}
