
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Example: index.css */
@font-face {
    font-family: 'Atyp';
    src: url('./assets/fonts/Atyp-Light.ttf') format('truetype');
    /* Add additional formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Atyp';
    src: url('./assets/fonts/Atyp-Regular.ttf') format('truetype');
    /* Add additional formats if needed */
    font-weight: 300; /* Adjust as per your font's weight */
    font-style: normal;
  }
  
  .font-atyp {
    font-family: 'Atyp', sans-serif;
  }
  
  .font-light {
    font-family: 'Atyp-Light', sans-serif;
  }
  