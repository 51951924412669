@media (max-width: 480px) {
    /* Styling for the dev-container on mobile */
    .dev-container {
      padding: 16px; /* Reduce padding for smaller screens */
      background-color: #f8f9fa; /* Lighten the background for better readability on mobile */
      text-align: center; /* Ensure content remains centered */
    }
  
    /* Styling for the dev-title on mobile */
    .dev-title {
      font-size: 45px; /* Reduce title font size for mobile */
      margin-bottom: 16px; /* Adjust margin for better spacing on mobile */
    }
  
    /* Optional adjustments for paragraph text */
    .dev-container p {
      font-size: 14px; /* Make the paragraph text slightly smaller */
      width: 100%; /* Allow full width for better readability */
      padding: 0 5px; /* Add a little padding on the sides */
    }
  }
  