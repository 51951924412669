@font-face {
  font-family: 'AtypText';
  src: url('../assets/fonts/AtypDisplay-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.footer {
  background-color: #f8f9fa;
  padding: 100px 0;
  font-family: 'AtypText', sans-serif;
  margin-top: 10px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  max-width: 1200px;
  margin: 80px auto;
  padding: 0 20px;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
}

.footer-section {
  margin: 0 20px;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: gray !important;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 8px;
}

.footer-section a {
  text-decoration: none;
  color: gray !important;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #0093E9;
}

.footer-social {
  text-align: center;
}

.footer-social .social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-social .social-icons a {
  color: gray !important;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-social .social-icons a:hover {
  color: #0093E9;
}

.footer-bottom {
  text-align: center;
  margin-top: 60px;
  font-size: 14px;
  color: gray !important;
  position: relative;
}

.back-to-top {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #0093E9;
  border-radius: 50%;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
}

.back-to-top i {
  font-size: 20px;
}

.back-to-top:hover {
  background-color: #006bb7;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer-section {
    margin: 10px 0;
    text-align: center;
  }
}
