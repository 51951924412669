/* Define the animation keyframes */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(.97, .97, .97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

/* Existing Styles */
.letschat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
}

.letschat-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  background-color: #0093E9;
  background-image: linear-gradient(180deg, #0093E9 0%, #001fc1 100%);
  padding: 20px 50px 0px 50px;
  border-radius: 60px;
  overflow: hidden;
}

.letschat-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Apply the animation to the text */
.awesome {
  font-size: 90px;
  line-height: 0.8;
  font-weight: 400;
  margin: 0;
  color: #fff;
  animation: bounceIn 2s ease-in-out; /* Animation added here */
}

.new {
  font-size: 30px;
  margin: 10px 0;
  color: #fff;
  animation: fadeIn 3s ease-in-out; /* Animation added here */
}

.outstand {
  font-weight: bold;
  color: #fff;
}

.letschat-people {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.letschat-people span {
  font-size: 1em;
  font-weight: 900;
  margin-left: 10px;
}

.people-icons {
  display: flex;
  position: relative;
}

.person {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -7px; /* To overlay the images */
  background-color: #2f69fd; /* Blue background */
  border: 1px solid #fff; /* White border */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Adjust icon size */
  color: #fff; /* White icon color */
}

.letschat-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.letschat-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .letschat-content {
    flex-direction: column;
    padding: 20px 20px 0px 20px;
    border-radius: 30px;
  }

  .awesome {
    font-size: 60px;
    line-height: 1;
  }

  .new {
    font-size: 24px;
    margin: 8px 0;
  }

  .letschat-text {
    padding: 10px;
  }

  .letschat-people span {
    font-size: 0.9em;
  }

  .person {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }

  .letschat-image-container {
    margin-top: 20px; /* To add space between text and image on mobile */
  }
}

@media (max-width: 480px) {

  .letschat-content {
    display: flex;
    width: 100%;
    height: 800px;
    background-color: #0093E9;
    background-image: linear-gradient(180deg, #0093E9 0%, #001fc1 100%);
    overflow: hidden;
  }


  .letschat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: #fff;
    width: 100%;
  }
  
  
  .awesome {
    font-size: 70px;
    line-height: 0.8;
    font-weight: 800;
    letter-spacing: -3px;
    margin-bottom: 20px;
  }

  .new {
    font-size: 20px;
  }

 

  .letschat-text {
    padding: 5px;
  }

  .letschat-people span {
    font-size: 0.8em;
  }

  .person {
    width: 18px;
    height: 18px;
    font-size: 14px;
  }

  .letschat-image-container {
    margin-top: 10px; /* To add space between text and image on mobile */
  }
}
