.about-page {
  font-family: Arial, sans-serif;
}

/* Mission, Vision, Values Sections */
.mission-section,
.values-section {
  background-color: #0056b3; /* Blue background color */
  color: white;
  padding: 60px 20px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column; /* Stacked layout for mobile */
  align-items: center; /* Center items */
  text-align: center;
}

.vision-section {
  background-image: url('../assets/images/abt-bg2.avif'); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  color: white;
  padding: 60px 20px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column; /* Stacked layout for mobile */
  align-items: center; /* Center items */
  text-align: center;
}

.mission-section h2,
.vision-section h2,
.values-section h2 {
  font-size: 20px; /* Smaller font size for mobile */
  font-weight: bold;
  margin-bottom: 15px;
}

.mission-section p,
.vision-section p,
.values-section ul {
  font-size: 14px; /* Reduced font size for readability */
  line-height: 1.6;
  max-width: 100%; /* Full width on mobile */
}

.values-section ul {
  list-style-type: none;
  padding: 0;
}

.values-section ul li {
  margin: 10px 0;
}

/* Team Section */
.team-section {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: center;
}

/* Grid layout for team members */
.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for mobile */
  gap: 15px;
}

.team-member img {
  width: 80%; /* Adjust size for mobile */
  height: auto;
  border-radius: 50%;
  margin: 0 auto; /* Center images */
  display: block; /* Ensure the image is treated as a block for proper centering */
}

.team-member p {
  margin-top: 10px;
  font-size: 14px; /* Smaller font for mobile */
  font-weight: bold;
}

/* Media Query for larger screens (Tablets and above) */
@media (min-width: 768px) {
  .mission-section,
  .vision-section,
  .values-section {
    padding: 100px 60px; /* Increase padding for larger screens */
    flex-direction: row; /* Horizontal layout for larger screens */
    text-align: left;
    justify-content: space-between; /* Spread items horizontally */
  }

  .mission-section h2,
  .vision-section h2,
  .values-section h2 {
    font-size: 24px; /* Larger font size for larger screens */
  }

  .mission-section p,
  .vision-section p,
  .values-section ul {
    font-size: 16px;
    max-width: 800px; /* Limit width for readability on large screens */
  }

  .team-grid {
    grid-template-columns: repeat(4, 1fr); /* Four columns for larger screens */
  }

  .team-member img {
    width: 100%; /* Restore image size for larger screens */
  }
}

/* Media Query for mobile screens (480px and below) */
@media (max-width: 480px) {
  .hd-bg {
    width: 100%;
    height: 300px;
  }

  .abt-head {
    text-align: center;
    padding: 70px 10px;
    margin-bottom: 30px;
  }

  .abt-title {
    font-size: 48px;
    line-height: 1.1;
    letter-spacing: -2px;
  }

  .abt-para-text {
    display: none;
  }


  /* Team Section */
.team-section {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: center;
}


  .team-member img {
    margin-left: 300px;
    margin-right: auto; /* Ensures centering for mobile */
    width: 400px;
    height: 400px;
    background-color: red;
    display: block; /* Ensure centering works */

  }

.abt-core {

  font-size: 40px;

}

.team-container {

  display: flex;
  flex-direction: column;
  align-items: center;


}

}
