/* src/components/Service.css */
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.scale-up {
  transition: transform 0.3s ease-in-out;
}

.scale-up:hover {
  animation: scaleUp 0.3s forwards;
}

/* Responsive Typography and Layout Adjustments */
@media (max-width: 1024px) {
  .slider-container h1 {
    font-size: 2.5rem;
  }

  .slider-container p {
    font-size: 1rem;
  }

  .slider-content {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  /* Adjust layout for tablets */
  .slider-container {
    text-align: center;
  }

  .slider-container h1 {
    font-size: 2rem;
  }

  .slider-container p {
    font-size: 0.875rem;
  }
}

@media (max-width: 640px) {
  /* Mobile Adjustments */
  .slider-container {
    padding: 10px;
  }

  .slider-container h1 {
    font-size: 1.75rem;
  }

  .slider-container p {
    font-size: 0.75rem;
  }

  .slider-content {
    padding: 15px;
  }

  /* Grid becomes single column */
  .grid-cols-2, .grid-cols-3 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  /* Small mobile screens */
  .slider-container h1 {
    font-size: 1.5rem;
  }

  .slider-container p {
    font-size: 0.7rem;
  }

  .slider-content {
    font-size: 1rem;
    padding: 10px;
  }

  .text-center {

    padding: 20px;
  }

  .table-header-text {

    font-size: 36px;
  }
}
