/* Default desktop styling */
.testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    background-color: #f9fafb;
  }
  
  .testimonial-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .testimonial-heading span {
    color: #007bff;
  }
  
  .testimonial-card-container {
    display: flex;
    justify-content: center;
    max-width: 800px;
    width: 100%;
  }
  
  .testimonial-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .white-bg {
    background-color: #ffffff;
  }
  
  .blue-bg {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .testimonial-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .testimonial-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .testimonial-position {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 15px;
  }
  
  .testimonial-text {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .testimonial-buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .prev-button,
  .next-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .prev-button:hover,
  .next-button:hover {
    background-color: #0056b3;
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .testimonial-container {
      padding: 30px 10px;
    }
  
    .testimonial-heading {
      font-size: 1.75rem;
    }
  
    .testimonial-card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .testimonial-card {
      width: 90%;
      margin-bottom: 20px;
    }
  
    .testimonial-img {
      width: 60px;
      height: 60px;
    }
  
    .testimonial-name {
      font-size: 1.1rem;
    }
  
    .testimonial-position {
      font-size: 0.9rem;
    }
  
    .testimonial-text {
      font-size: 0.9rem;
    }
  
    .testimonial-buttons {
      flex-direction: row;
      gap: 10px;
    }
  
    .prev-button,
    .next-button {
      padding: 8px 16px;
      font-size: 1.25rem;
    }
  }
  