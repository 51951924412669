@media (max-width: 480px) {


.project-top {

    width: 100% !important;
    background: none;
}

.projects-title {

   font-size: 50px;
   line-height: 1.2;
}


.projects-para {

    width: 350px;
}

.projects-main-container {

    width: 100%;
}

    /* Category buttons container */
    .category-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      margin-top: 20px;
    }
  
    /* Category buttons styling */
    .category-btn {
      padding: 8px 12px;
      font-size: 0.875rem;
      flex: 1 1 45%; /* Adjust button size and allow wrapping */
      text-align: center;
      word-wrap: break-word; /* Allows long words to break onto the next line */
      line-height: 1.2rem; /* Adjusts line height to fit two lines neatly */
    }
  
    /* Optional: Add extra spacing for buttons */
    .project-cat {
        display: none;
    }
  }
  